export default function Contact() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-700 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-200">
                Get in touch
              </h2>
              <p className="mt-4 leading-7 text-gray-300">
                We'd love to hear from you! Send us a message with any
                questions, comments, or concerns you may have.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              <div className="rounded-2xl bg-gray-800 p-10 border-2 border-black">
                <h3 className="text-base font-semibold leading-7 text-gray-200">
                  Feature requests
                </h3>
                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-400">
                  <div>
                    <dt className="sr-only">Email</dt>
                    <dd>
                      <a
                        className="font-semibold text-indigo-400"
                        href="mailto:collaborate@example.com"
                      >
                        features@pokee.io
                      </a>
                    </dd>
                  </div>
                  <div className="mt-1">
                    <dd>
                      For any feature requests you may like to see next in
                      Pokee.
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="rounded-2xl bg-gray-800 p-10  border-2 border-black">
                <h3 className="text-base font-semibold leading-7 text-gray-200">
                  General questions
                </h3>
                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-400">
                  <div>
                    <dt className="sr-only">Email</dt>
                    <dd>
                      <a
                        className="font-semibold text-indigo-400"
                        href="mailto:press@example.com"
                      >
                        people@pokee.io
                      </a>
                    </dd>
                  </div>
                  <div className="mt-1">
                    <dd>For any general questions.</dd>
                  </div>
                </dl>
              </div>
              <div className="rounded-2xl bg-gray-800 p-10  border-2 border-black">
                <h3 className="text-base font-semibold leading-7 text-gray-200">
                  Pricing
                </h3>
                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-400">
                  <div>
                    <dt className="sr-only">Email</dt>
                    <dd>
                      <a
                        className="font-semibold text-indigo-400"
                        href="mailto:careers@example.com"
                      >
                        pricing@pokee.io
                      </a>
                    </dd>
                  </div>
                  <div className="mt-1">
                    <dd>
                      For any questions regarding pricing or billing for Pokee.
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
