/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useSession } from "@clerk/clerk-react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
  CheckBadgeIcon,
  MagnifyingGlassIcon,
  RectangleStackIcon,
} from "@heroicons/react/20/solid";
import {
  DocumentPlusIcon,
  FolderIcon,
  FolderPlusIcon,
  HashtagIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { defaultAuthCatcherRelogin } from "./App";
import { socket } from "./game";

const projects = [
  { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
  // More projects...
];
const recent = [projects[0]];
const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "N", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "F", url: "#" },
  { name: "Add hashtag...", icon: HashtagIcon, shortcut: "H", url: "#" },
  { name: "Add label...", icon: TagIcon, shortcut: "L", url: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Search({
  roomId,
  open,
  setOpen,
  catchJiraLink,
  tasks,
}) {
  const [query, setQuery] = useState("");
  const s = useSession();
  const [results, setResults] = useState([]);
  const [exactResults, setExactResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exact, setExact] = useState(true);

  const handleSearch = useCallback(
    debounce((value, e) => {
      t(value, e);
    }, 500),
    []
  );

  const t = async (q, e) => {
    if (!q) {
      setLoading(false);
      return;
    }
    const r = await axios
      .get(window.BACKEND_URL + `/${e ? "exact" : "issues"}-search/${q}`, {
        headers: {
          s_token: await s.session.getToken(),
          s_id: s.session.id,
        },
      })
      .catch((e) => {
        defaultAuthCatcherRelogin(e, catchJiraLink);
        setLoading(false);
      });
    if (!r?.data) {
      return;
    }
    if (e) {
      setExactResults(
        r.data.issues.map((i) => ({
          ...i,
          summary: i.fields.summary,
          summaryText: i.fields.summary,
        }))
      );
    } else {
      setResults(r.data.sections);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    handleSearch(query, exact);
  }, [query, exact]);

  const [activeKeys, setActiveKeys] = useState([]);

  useEffect(() => {
    if (activeKeys.includes("Meta") && activeKeys.includes("o")) {
      setOpen((p) => !p);
      setActiveKeys([]);
    }
  }, [activeKeys]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Meta" || e.key === "o") {
        setActiveKeys((keys) => [...keys, e.key]);
      }
    };
    const onKeyUp = (e) => {
      setActiveKeys((keys) => keys.filter((k) => k !== e.key));
    };
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
    // eslint-disable-next-line
  }, []);

  const addItem = async (item) => {
    socket.emit("add-task-to-room", { task: item, roomId });
  };

  function extractSelectedIssues(inputString) {
    const urls = inputString.split(/[\s,]+/); // Split by space or comma
    const issues = [];

    urls.forEach((url) => {
      // Check for 'selectedIssue' parameter in the query string
      const matchQuery = url.match(/[?&]selectedIssue=([^&#]+)/);
      if (matchQuery) {
        issues.push(matchQuery[1]);
      } else {
        // Check for a key after '/browse/'
        const matchBrowse = url.match(/\/browse\/([^\/?&#]+)/);
        if (matchBrowse) {
          issues.push(matchBrowse[1]);
        }
      }
    });

    return issues;
  }

  const smartFindQuery = async (query) => {
    // remove , from query
    const urlQuery = extractSelectedIssues(query);
    if (urlQuery.length) {
      setQuery(urlQuery.join(" "));
      return;
    }
    const q = query.replace(",", " ");
    setQuery(q);
  };

  const addCompleteQueryResults = async () => {
    // first find the items
    const keys = [];
    if (!exact) {
      results.forEach((result) => {
        result.issues?.forEach((item) => {
          socket.emit("add-task-to-room", { task: item, roomId });
          keys.push(item.key);
        });
      });
    } else {
      exactResults.forEach((item) => {
        socket.emit("add-task-to-room", { task: item, roomId });
        keys.push(item.key);
      });
    }
    await axios
      .get(window.BACKEND_URL + `/quick-get/${keys.join(",")}`, {
        headers: {
          s_token: await s.session.getToken(),
          s_id: s.session.id,
        },
      })
      .catch((e) => {
        defaultAuthCatcherRelogin(e, catchJiraLink);
        setLoading(false);
      });
  };

  return (
    <>
      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setQuery("")}
        appear
      >
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
                {true ? (
                  <Combobox onChange={addItem}>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-sm"
                        placeholder={
                          exact
                            ? "EN-4576, jira.atlassian.com/browse/EN-4576"
                            : "Search recent in Jira opened issues, projects, and more"
                        }
                        onChange={(event) => {
                          smartFindQuery(event.target.value);
                        }}
                      />
                      {/* {query.length ? (
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <button
                            onClick={addCompleteQueryResults}
                            type="button"
                            disabled={loading}
                            className="rounded disabled:bg-gray-100 bg-indigo-50 px-2 py-1 text-xs font-semibold text-gray-800 shadow-sm hover:bg-indigo-100"
                          >
                            {loading ? <>...</> : "Add all"}
                          </button>
                        </Combobox.Button>
                      ) : null} */}
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <button
                          onClick={() => setExact((p) => !p)}
                          type="button"
                          disabled={loading}
                          className="rounded disabled:bg-gray-100 bg-indigo-50 px-2 py-1 text-xs font-semibold text-gray-800 shadow-sm hover:bg-indigo-100"
                        >
                          {exact ? "Or history search" : "Or exact search"}
                        </button>
                      </Combobox.Button>
                    </div>
                    {loading ? (
                      <div className="w-full  h-20 flex justify-center items-center">
                        <svg
                          aria-hidden="true"
                          class="inline w-4 h-4 text-gray-200 animate-spin  fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                    ) : (
                      <>
                        {(query === "" ||
                          results.length > 0 ||
                          exactResults.length > 0) && (
                          <Combobox.Options
                            static
                            className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
                          >
                            {exact && (
                              <>
                                <li className="p-2">
                                  {exactResults.length > 0 && (
                                    <>
                                      <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-900">
                                        Exact key search results
                                      </h2>
                                      <div className="flex w-full justify-end">
                                        <button
                                          type="button"
                                          onClick={addCompleteQueryResults}
                                          disabled={loading}
                                          className="rounded mb-2 disabled:bg-gray-100 bg-indigo-50 px-2 py-1 text-xs font-semibold text-gray-800 shadow-sm hover:bg-indigo-100"
                                        >
                                          Add all
                                        </button>
                                      </div>
                                    </>
                                  )}
                                  {exact &&
                                    exactResults?.map((item) => {
                                      return (
                                        <Combobox.Option
                                          key={item.id}
                                          value={item}
                                          className={({ active }) =>
                                            classNames(
                                              "flex cursor-default select-none rounded-xl p-3",
                                              active && "bg-gray-100"
                                            )
                                          }
                                        >
                                          {({ active }) => (
                                            <>
                                              <div
                                                className={classNames(
                                                  "flex h-10 w-10 flex-none items-center justify-center rounded-lg"
                                                )}
                                              >
                                                <RectangleStackIcon
                                                  className="h-6 w-6 text-indigo-400"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                              <div className="ml-4 flex-auto">
                                                <p
                                                  className={classNames(
                                                    "text-sm font-medium",
                                                    active
                                                      ? "text-gray-900"
                                                      : "text-gray-700"
                                                  )}
                                                >
                                                  {item.key}
                                                </p>
                                                <p
                                                  className={classNames(
                                                    "text-sm",
                                                    active
                                                      ? "text-gray-700"
                                                      : "text-gray-500"
                                                  )}
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.summary,
                                                  }}
                                                ></p>
                                              </div>
                                              {tasks.find(
                                                (t) => t.key === item.key
                                              ) && (
                                                <div className="text-xs">
                                                  Added{" "}
                                                  <CheckBadgeIcon className="text-green-500 w-4 h-4" />
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </Combobox.Option>
                                      );
                                    })}
                                </li>
                              </>
                            )}
                            {!exact &&
                              results.map((result) => {
                                return (
                                  <li className="p-2 ">
                                    {results.length > 0 && (
                                      <>
                                        <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-900">
                                          History search results
                                        </h2>
                                        <div className="flex w-full justify-end">
                                          <button
                                            type="button"
                                            onClick={addCompleteQueryResults}
                                            disabled={loading}
                                            className="rounded mb-2 disabled:bg-gray-100 bg-indigo-50 px-2 py-1 text-xs font-semibold text-gray-800 shadow-sm hover:bg-indigo-100"
                                          >
                                            Add all
                                          </button>
                                        </div>
                                      </>
                                    )}
                                    <ul className="text-sm text-gray-700">
                                      {result.issues?.map((item) => (
                                        <Combobox.Option
                                          key={item.id}
                                          value={item}
                                          className={({ active }) =>
                                            classNames(
                                              "flex cursor-default select-none rounded-xl p-3",
                                              active && "bg-gray-100"
                                            )
                                          }
                                        >
                                          {({ active }) => (
                                            <>
                                              <div
                                                className={classNames(
                                                  "flex h-10 w-10 flex-none items-center justify-center rounded-lg"
                                                )}
                                              >
                                                <RectangleStackIcon
                                                  className="h-6 w-6 text-indigo-400"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                              <div className="ml-4 flex-auto">
                                                <p
                                                  className={classNames(
                                                    "text-sm font-medium",
                                                    active
                                                      ? "text-gray-900"
                                                      : "text-gray-700"
                                                  )}
                                                >
                                                  {item.key}
                                                </p>
                                                <p
                                                  className={classNames(
                                                    "text-sm",
                                                    active
                                                      ? "text-gray-700"
                                                      : "text-gray-500"
                                                  )}
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.summary,
                                                  }}
                                                ></p>
                                              </div>
                                              {tasks.find(
                                                (t) => t.key === item.key
                                              ) && (
                                                <div className="text-xs">
                                                  Added{" "}
                                                  <CheckBadgeIcon className="text-green-500 w-4 h-4" />
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </Combobox.Option>
                                      ))}
                                    </ul>
                                  </li>
                                );
                              })}

                            {/* {query === "" && (
                      <li className="p-2">
                        <h2 className="sr-only">Quick actions</h2>
                        <ul className="text-sm text-gray-700">
                          {quickActions.map((action) => (
                            <Combobox.Option
                              key={action.shortcut}
                              value={action}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active &&
                                    "bg-gray-900 bg-opacity-5 text-gray-900"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <action.icon
                                    className={classNames(
                                      "h-6 w-6 flex-none text-gray-900 text-opacity-40",
                                      active && "text-opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {action.name}
                                  </span>
                                  <span className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                    <kbd className="font-sans">⌘</kbd>
                                    <kbd className="font-sans">
                                      {action.shortcut}
                                    </kbd>
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )} */}
                          </Combobox.Options>
                        )}
                      </>
                    )}
                    {query === "" && results.length === 0 && (
                      <div className="px-6 py-14 text-center sm:px-14">
                        <FolderIcon
                          className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                          aria-hidden="true"
                        />
                        {exact ? (
                          <p className="mt-4 text-sm text-gray-900">
                            You can search for exact keys, but also for URL's
                            that contain keys and even multiple keys / URL's.
                          </p>
                        ) : (
                          <p className="mt-4 text-sm text-gray-900">
                            You can search for issues, import a link, or batch
                            import links, or keys that you have recently opened
                            in Jira.
                          </p>
                        )}
                      </div>
                    )}
                    {((exact &&
                      !loading &&
                      query !== "" &&
                      exactResults.length === 0) ||
                      (!exact &&
                        !loading &&
                        query !== "" &&
                        results.length === 0)) && (
                      <div className="px-6 py-14 text-center sm:px-14">
                        <FolderIcon
                          className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                          aria-hidden="true"
                        />
                        <p className="mt-4 text-sm text-gray-900">
                          We couldn't find any projects with that term. Please
                          try again.
                        </p>
                      </div>
                    )}
                  </Combobox>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
