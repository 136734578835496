import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowRightOnRectangleIcon,
  CheckBadgeIcon,
  EyeIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateOrJoinModal({
  onCreate,
  onJoin,
  onSpectate,
  unjoinedGameId,
  currentlyNotInAGame,
}) {
  const [poGameId, setPoGameId] = useState("");
  const navigate = useNavigate();
  const uuidV4Regex =
    /([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i;

  useEffect(() => {
    const match = poGameId.match(uuidV4Regex);
    const m = match ? match[0] : null;
    if (m) {
      navigate(`/game/${m}`);
      window.location.reload();
    }
  }, [poGameId]);

  return (
    <Transition.Root
      show={currentlyNotInAGame}
      as={Fragment}
      onClose={() => null}
    >
      <Dialog as="div" className="relative z-10">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckBadgeIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      Hi! 🙋‍♀️
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-200">
                        Start by creating {unjoinedGameId ? "or joining" : ""} a
                        game.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  {unjoinedGameId ? (
                    <>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-none  "
                        onClick={onJoin}
                      >
                        Join this game
                        <ArrowRightOnRectangleIcon
                          className="h-6 w-6 text-white ml-2"
                          aria-hidden="true"
                        />
                      </button>
                      <div className="relative my-2">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-700" />
                        </div>
                        <div className="relative flex justify-center text-sm font-medium leading-6">
                          <span className="bg-gray-800 px-6 text-gray-300">
                            Or
                          </span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-gray-700 border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={onSpectate}
                      >
                        Spectate this game
                        <EyeIcon
                          className="h-6 w-6 text-white ml-2"
                          aria-hidden="true"
                        />
                      </button>
                      <div className="relative my-2">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-700" />
                        </div>
                        <div className="relative flex justify-center text-sm font-medium leading-6">
                          <span className="bg-gray-800 px-6 text-gray-300">
                            Or
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-5">
                        <p className="text-xs font-bold ml-2  text-gray-200">
                          Ask your host for a link to join the game.
                        </p>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={poGameId}
                          onChange={(e) => setPoGameId(e.target.value)}
                          className="block mt-2 w-full rounded-full border-0 px-4 py-1.5 text-gray-200 shadow-sm bg-gray-700  sm:text-sm sm:leading-6"
                          placeholder="https://pokee.io/game/d5b46116-3661-42ef-966a-4886bc4badb4"
                        />
                      </div>
                      <div className="relative my-2">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-700" />
                        </div>
                        <div className="relative flex justify-center text-sm font-medium leading-6">
                          <span className="bg-gray-800 px-6 text-gray-300">
                            Or
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    type="button"
                    className="inline-flex w-full mb-1 justify-center rounded-lg bg-indigo-600 border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={onCreate}
                  >
                    Create a new game
                    <PuzzlePieceIcon
                      className="h-6 w-6 text-white ml-2"
                      aria-hidden="true"
                    />
                  </button>
                  <div className="relative">
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-gray-800 px-6 text-gray-500">
                        Games are saved for 48 hours
                      </span>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
