import { Dialog, Transition } from "@headlessui/react";
import { RectangleStackIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { socket } from "./game";

export default function CreateModal({ open, setOpen, onCreate, gameId }) {
  const [taskDescription, setTaskDdescription] = useState("");

  const addTask = () => {
    socket.emit("add-task-to-room", {
      task: {
        summary: taskDescription,
        custom: true,
      },
      roomId: gameId,
    });
    setOpen(false);
  };

  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                    <RectangleStackIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      Create a task
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-200">
                        Provide a small description of the task you want to
                        create. We'll add more features later.
                      </p>
                      <div className=" mt-5 flex w-full flex-col text-white items-start">
                        <label
                          htmlFor="name"
                          className=" block pl-4 text-sm font-medium leading-6 text-gwhite"
                        >
                          Description
                        </label>
                        <div className="mt-2 w-full">
                          <textarea
                            rows={2}
                            type="text"
                            autoFocus={open}
                            name="name"
                            id="name"
                            value={taskDescription}
                            onChange={(e) =>
                              setTaskDdescription(e.target.value)
                            }
                            className="block w-full rounded-md bg-gray-700 border-0 px-4 py-1.5 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            placeholder="Jane Smith"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    disabled={!taskDescription.length}
                    className="inline-flex w-full disabled:bg-indigo-600/20 justify-center rounded-md bg-indigo-600  border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => addTask()}
                  >
                    Create task 🚀
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
