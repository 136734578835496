import { SignOutButton } from "@clerk/clerk-react";
import { Dialog, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

const TiltCard = ({ name, player }) => {
  console.log({ player });
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const rotateX = useTransform(
    mouseYSpring,
    [-0.5, 0.5],
    ["17.5deg", "-17.5deg"]
  );
  const rotateY = useTransform(
    mouseXSpring,
    [-0.5, 0.5],
    ["-17.5deg", "17.5deg"]
  );

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        rotateY,
        rotateX,
        transformStyle: "preserve-3d",
      }}
      className="relative h-96 w-72 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300"
    >
      <div
        style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
          backgroundImage: `
          radial-gradient(rgb(240, 241, 255) 14%, transparent 14%),
           radial-gradient(rgb(240, 241, 255) 14%, transparent 14%)`,
          backgroundPosition: "0px 0px, 8px 8px",
          backgroundSize: "16px 16px",
          backgroundColor: "rgba(79, 70, 229, 10)",
          boxShadow: "inset 0 0 0px 10px rgba(79, 70, 229, 10)",
        }}
        className="absolute border-2 border-black inset-4 flex flex-col justify-end  rounded-xl bg-white shadow-lg"
      >
        <div
          style={{
            transform: "translateZ(50px)",
          }}
          className="text-2xl text-left font-bold text-white p-2 pb-4 bg-gray-900 rounded-md flex flex-col justify-start"
        >
          <p>{name}</p>
          <p className="text-sm font-medium mb-2">Beginner</p>
          <p className="text-xs font-medium text-gray-500">
            Total votes: {player?.totalVotes || 0}
          </p>
          <p className="text-xs font-medium  text-gray-500">
            correct votes: {player?.correctVotes || 0}
          </p>
          <p className="text-xs font-medium  text-gray-500">
            Vote rate:{" "}
            {Math.ceil(
              ((player?.correctVotes || 1) / (player.totalVotes || 1)) * 100
            )}
            %
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default function Settings({ open, setOpen, onSave, name, player }) {
  const [newName, setNewName] = useState(name);
  const nav = useNavigate();

  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                    <UserCircleIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      All about you, {newName}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="flex justify-center items-center p-10">
                        <TiltCard
                          name={newName}
                          player={player}
                          width={"160px"}
                          height={"240px"}
                          myValue={""}
                          show={false}
                          x={-180}
                        />
                      </div>
                      <p className="text-sm text-gray-200">
                        For now, you can only change your name. We'll add more
                        later.
                      </p>
                      <div className=" mt-2 flex w-full flex-col text-white items-start">
                        <label
                          htmlFor="name"
                          className=" block pl-4 text-sm font-medium leading-6 text-gwhite"
                        >
                          Name
                        </label>
                        <div className="mt-2 w-full">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            className="block w-full bg-gray-700 rounded-full border-0 px-4 py-1.5 text-gray-200 shadow-sm ring-1 ring-inset 0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            placeholder="Jane Smith"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    disabled={newName === name}
                    className="inline-flex w-full  justify-center rounded-md bg-indigo-500  border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => onSave(newName)}
                  >
                    Save changes 🚀
                  </button>
                  <SignOutButton
                    signOutCallback={() => nav("/")}
                    className="inline-flex w-full  justify-center rounded-md bg-indigo-500  border-2 border-black  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-4"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
