/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useSession, useUser } from "@clerk/clerk-react";
import Tour from "reactour";

import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import {
  CogIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import axios from "axios";
import { animate, motion, useMotionValue } from "framer-motion";
import { Fragment, useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { useNavigate, useParams } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import { io } from "socket.io-client";
import { defaultAuthCatcherRelogin } from "./App";
import CreateModal from "./Create";
import CreateOrJoinModal from "./CreateOrJoin";
import GameSettings, { sequences } from "./GameSettings";
import Jiraconfig from "./Jiraconfig";
import LinkModal from "./Link";
import { useNotifyState } from "./Notification";
import PremiumError from "./PremiumError";
import SaveModall from "./Save";
import Search from "./Search";
import Settings from "./Settings";
import Share from "./Share";
import { useContainerDimensions } from "./helpers";

const statuses = {
  Complete: "text-green-700 bg-green-50 ring-green-600/20",
  "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
  Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

const steps = [
  {
    selector: "#add",
    content:
      "You start by adding tasks from Jira or creating tasks yourself. If you join an existing game, the host will add the tasks.",
  },
  {
    selector: "#task-0",
    content: "You can click on a task to highlight it and to start voting.",
  },
  {
    selector: "#sequence",
    content: "You select the points which you see fit for the task.",
  },
  {
    selector: "#board",
    content: "Other people also vote on the task.",
  },
  {
    selector: "#reveal",
    content: "you reveal the votes and decide the final vote.",
  },
  {
    selector: "#task-0",
    content: "You set the final vote and move on to the next task.",
  },
  // ...
];

const variants = {
  waiting: {
    backgroundImage: `
   radial-gradient(rgb(91, 98, 113) 10%, transparent 10%),
    radial-gradient(rgb(91, 98, 113) 10%, transparent 10%)`,
    backgroundPosition: "0px 0px, 8px 8px",
    backgroundSize: "16px 16px",
    backgroundColor: "rgb(26, 32, 45)",
    boxShadow: "inset 0 0 0px 10px rgb(26, 32, 45)",
  },
  selected: {
    backgroundImage: `
   radial-gradient(rgb(240, 241, 255) 14%, transparent 14%),
    radial-gradient(rgb(240, 241, 255) 14%, transparent 14%)`,
    backgroundPosition: "0px 0px, 8px 8px",
    backgroundSize: "16px 16px",
    backgroundColor: "rgb(79, 70, 229)",
    boxShadow: "inset 0 0 0px 10px rgb(79, 70, 229)",
    scale: 1.03,
    transition: {
      duration: 0.01,
      stiffness: 500,
      type: "spring",
    },
  },
};

export const selectionVariants = {
  notSelected: {
    y: 0,
  },
  selected: {
    y: -2,
    backgroundColor: "rgb(79, 70, 229)",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export let socket;

export default function Game() {
  const { gameId } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [show, showValue] = useState(false);
  const header = useRef;
  const [myValue, setValue] = useState("");
  const [players, setPlayers] = useState({});
  const x = useMotionValue(-180);
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const [me, setMe] = useState();
  const [waitingForRoomExistsLoading, setWaitingForRoomExistsLoading] =
    useState(true);
  const [spectatorMode, setSpectatorMode] = useState(false);
  const [roomExists, setRoomExists] = useState(false);
  const [joinedThisRoom, setJoinedThisRoom] = useState(false);
  const [roomCreator, setRoomCreator] = useState(false);
  const [tasksInRoom, setTasksInRoom] = useState([]);
  const [highlightedTask, setHighlightedTask] = useState();
  const [jiraLink, setJiraLink] = useState();
  const [hostWatchMode, setHostWatchMode] = useState(false);
  const [seq, setSequence] = useState(sequences[0]);

  const [searchOpen, setSearchOpen] = useState(false);
  useEffect(() => {
    const onKeyDown = (e) => {
      if (
        typeof parseInt(e.key) === "number" &&
        !isNaN(parseInt(e.key)) &&
        seq.values.includes(e.key)
      ) {
        changeVote(e.key);
      }
    };

    if (searchOpen) {
      document.removeEventListener("keydown", onKeyDown);
    } else {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line
  }, [searchOpen]);

  const reveal = (skipSocket) => {
    setValue("");
    animate(x, 0, {
      type: "spring",
      stiffness: 100,
    });
    if (skipSocket === true) {
      return;
    }
    socket.emit("reveal-cards", gameId);
  };

  const deleteRoom = () => {
    socket.emit("delete-room", { roomId: gameId, userId: u.user.id });
    animate(x, -180, {
      type: "spring",
      stiffness: 100,
    });
    setValue("");
    setJoinedThisRoom(false);
    if (roomCreator) {
      setRoomExists(false);
      setRoomCreator(false);
    }
    setTasksInRoom([]);
    setPlayers({ [u.user.id]: { name: u.user.firstName } });
  };

  const hightlightTask = (taskId) => {
    socket.emit("highlight-task", { roomId: gameId, taskId });
  };

  const unhighlightTask = (taskId) => {
    socket.emit("unhighlight-task", { roomId: gameId, taskId });
  };

  const reset = (skipEmit) => {
    setValue("");
    animate(x, -180, {
      type: "spring",
      stiffness: 100,
    });
    setValue("");
    setPlayers((p) => {
      const f = {};
      Object.keys(p).forEach((key) => {
        f[key] = { ...p[key], vote: "" };
      });
      return f;
    });
    if (skipEmit === true) {
      return;
    }
    socket.emit("reset-cards", gameId);
  };

  const createRoom = () => {
    socket.emit("create-room");
  };

  const [forcedReconnect, setForcedReconnect] = useState(false);

  const joinRoom = () => {
    if (localStorage.getItem("tour") !== "true") {
      setIsTourOpen(true);
    }
    socket.emit("join-room", {
      roomId: gameId,
    });
  };

  const onSpectate = () => {
    socket.emit("spectate-room", {
      roomId: gameId,
    });
  };

  const createOrJoin = () => {
    animate(x, -180, {
      type: "spring",
      stiffness: 100,
    });
    setValue();
    if (!roomExists && !joinedThisRoom) {
      createRoom();
      return;
    }
    if (roomExists && !joinedThisRoom) {
      joinRoom();
      return;
    }
    createRoom();
  };

  const leaveRoom = () => {
    socket.emit("leave-room", gameId);
    animate(x, -180, {
      type: "spring",
      stiffness: 100,
    });
    setValue("");
    setJoinedThisRoom(false);
    if (roomCreator) {
      setRoomExists(false);
      setRoomCreator(false);
    }
    setTasksInRoom([]);
    setPlayers({ [u.user.id]: { name: u.user.firstName } });
  };

  useEffect(
    () =>
      x.onChange((latest) => {
        showValue(latest > -90);
      }),
    []
  );

  const ref = useRef();
  const dims = useContainerDimensions(ref);
  const [spectators, setSpectators] = useState([]);

  const getUserInfo = async () => {
    const user = await axios
      .get(window.BACKEND_URL + "/me", {
        headers: {
          s_token: await s.session.getToken(),
          s_id: s.session.id,
        },
      })
      .catch((e) => defaultAuthCatcherRelogin(e, setJiraLink));
    setMe(user?.data);
  };

  useEffect(() => {
    getUserInfo();
    setPlayers((p) => {
      return { ...p, [u.user.id]: { name: u.user.firstName } };
    });
  }, []);

  const changeVote = (vote, skipEmit) => {
    setValue(vote);
    setPlayers((p) => {
      const q = p[u.user.id];
      if (!q) {
        return { ...p, [u.user.id]: { vote } };
      }
      q.vote = vote;
      return { ...p, [u.user.id]: q };
    });
    if (skipEmit === true) {
      return;
    }
    socket.emit("player-voted", { roomId: gameId, vote });
  };

  const [premiumError, setPremiumError] = useState("");
  useEffect(() => {
    if (!header?.current?.clientHeight) {
      return;
    }
    setHeight(header?.current?.clientHeight);
  }, [header?.current?.clientHeight]);
  const u = useUser();
  const s = useSession();
  const n = useNotifyState();
  useEffect(() => {
    socket = io(window.SOCKET_URL, {
      transports: ["websocket"],
    });
    socket.auth = async (cb) => {
      cb({
        userId: u.user.id,
        s_id: s.session.id,
        s_token: await s.session.getToken(),
      });
    };
    socket.emit("check-room", gameId);
    socket.on("player-change-vote", (player, vote) => {
      setPlayers((p) => {
        const f = { ...p };
        if (!vote) {
          delete f[player.id]?.vote;
          return f;
        }
        if (f[player.id]) {
          f[player.id].vote = vote;
        }
        return f;
      });
    });
    socket.on("disconnect", () => {
      setPlayers();
      setRoomCreator(false);
      setRoomExists(false);
      setValue("");
      if (!forcedReconnect) {
        setJoinedThisRoom(false);
        return;
      }
    });
    socket.on("force-players", (players) => {
      setPlayers(() => {
        const f = {};
        players.forEach((player) => {
          f[player.id] = {
            name: player.name,
            vote: player?.vote || "",
            totalVotes: player?.totalVotes || 0,
            correctVotes: player?.correctVotes || 0,
          };
        });
        return f;
      });
    });
    socket.on("room-deleted", () => {
      animate(x, -180, {
        type: "spring",
        stiffness: 100,
      });
      setValue("");
      setJoinedThisRoom(false);
      setRoomExists(false);
      setPlayers({ [u.user.id]: { name: u.user.firstName } });
    });
    socket.on("highlighted-task", (taskId) => {
      setHighlightedTask(taskId);
    });
    socket.on("room-exists", (exists) => {
      setWaitingForRoomExistsLoading(false);
      setRoomExists(exists);
    });
    socket.on("force-tasks-in-room", ({ tasks, highlight }) => {
      setTasksInRoom(tasks);
      setHighlightedTask(highlight);
    });
    socket.on("tasks-in-room", (tasks) => {
      setTasksInRoom(tasks);
    });
    socket.on("room-info", (info) => {
      if (info.host_id === u.user.id && !info.spectating) {
        setRoomCreator(true);
      }
    });
    socket.on("room-updated", (info) => {
      console.log({ info });
      if (info?.sequenceId !== undefined) {
        setSequence(
          sequences.find((s) => s.id === info.sequenceId) || sequences[0]
        );
      }
    });
    socket.on("spectators", (spectators) => {
      setSpectators(spectators);
    });
    socket.on("created-room", (roomId) => {
      if (localStorage.getItem("tour") !== "true") {
        setIsTourOpen(true);
      }
      setRoomCreator(true);
      setRoomExists(true);
      setJoinedThisRoom(true);
      setPlayers({ [u.user.id]: { name: u.user.firstName } });
      navigate(`/game/${roomId}`);
    });
    socket.on("game-error", (e) => {
      n.setNotification({
        title: `Oops!`,
        sub: e.error,
        Icon: () => <FaceFrownIcon className="text-orange-400 h-6 w-6" />,
      });
    });
    socket.on("i-joined-room", () => {
      setJoinedThisRoom(true);
      setForcedReconnect(false);
    });
    socket.on("i-spectate-room", () => {
      setJoinedThisRoom(true);
      setPlayers((p) => {
        delete p[u.user.id];
        return p;
      });
      setSpectatorMode(true);
    });
    socket.on("spectates-room", (players) => {
      players.forEach((player) => {
        if (player.id !== u.user.id) {
          n.setNotification({
            title: `Say hi!`,
            sub: `${player.name} is spectating.`,
            Icon: () => <EyeIcon className="text-green-400 h-6 w-6" />,
          });
        }
      });
    });
    socket.on("joined-room", (players) => {
      setPlayers((p) => {
        const f = {};
        players.forEach((player) => {
          if (player.id !== u.user.id) {
            n.setNotification({
              title: `Say hi!`,
              sub: `${player.name} joined the game.`,
              Icon: () => <FaceSmileIcon className="text-green-400 h-6 w-6" />,
            });
          }
          f[player.id] = {
            name: player.name,
            vote: player?.vote || "",
            totalVotes: player?.totalVotes || 0,
            correctVotes: player?.correctVotes || 0,
          };
        });
        return { ...p, ...f };
      });
    });
    socket.on("premium-error", (e) => {
      console.log("-eerror", e.reason);
      setPremiumError(e.reason);
    });
    socket.on("left-room", (player) => {
      setPlayers((p) => {
        const f = { ...p };
        delete f[player.id];
        return f;
      });
    });
    socket.on("final-vote", ({ taskId, vote }) => {
      setTasksInRoom((t) => {
        const f = [...t];
        const task = f.find((task) => task.key === taskId);
        task.finalVote = vote;
        return f;
      });
      reset();
    });
    socket.on("reveal", () => {
      animate(x, 0, {
        type: "spring",
        stiffness: 100,
      });
      setValue("");
    });
    socket.on("reset", () => {
      animate(x, -180, {
        type: "spring",
        stiffness: 100,
      });
      setValue("");
      setPlayers((p) => {
        const f = {};
        Object.keys(p).forEach((key) => {
          f[key] = { ...p[key], vote: "" };
        });
        return f;
      });
    });
  }, []);

  const [saveModalOpen, setSaveModalOpen] = useState();
  const [commentModalOpen, setCommentModalOpen] = useState();
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  useEffect(() => {
    if (jiraLink) {
      console.log("JIRA LOINK");
      setLinkModalOpen(true);
    }
  }, [jiraLink]);

  const resetVote = (taskId) => {
    socket.emit("set-final-vote", { roomId: gameId, taskId, vote: undefined });
  };

  const onClose = () => {
    setSaveModalOpen();
  };

  const onJiraRedirect = (url) => {
    const u = url || jiraLink;
    setJiraLink(u);
    setLinkModalOpen(true);
  };

  const gotoJira = () => {
    setLinkModalOpen(false);
    window.open(jiraLink);
  };

  const highlightedTaskFull = tasksInRoom.find(
    (t) => t.key === highlightedTask
  );

  const [syncingLoading, setSyncingLoading] = useState(false);

  const syncPointsToJira = async () => {
    setSyncingLoading(true);
    const r = await axios
      .post(
        window.BACKEND_URL + `/issues/sync-points-to-jira`,
        tasksInRoom
          .filter((t) => t.finalVote && !t.custom)
          .map((t) => ({ key: t.key, points: t.finalVote })),
        {
          headers: {
            s_token: await s.session.getToken(),
            s_id: s.session.id,
          },
        }
      )
      .catch((e) => {
        if (e?.response?.data?.type === "subscription") {
          setPremiumError(e.response.data.reason);
          return;
        }
        if (e?.response?.data?.type === "field-not-set") {
          setJiraConfigOpen(true);
          setSyncingLoading(false);
          return;
        }
        defaultAuthCatcherRelogin(e, setJiraLink);
        setSyncingLoading(false);
      });
    setSyncingLoading(false);
  };

  const [openSetting, setOpenSettings] = useState(false);

  const saveSettings = async (newName) => {
    await axios
      .post(
        window.BACKEND_URL + `/user`,
        { firstName: newName },
        {
          headers: {
            s_token: await s.session.getToken(),
            s_id: s.session.id,
          },
        }
      )
      .catch((e) => {
        defaultAuthCatcherRelogin(e, setJiraLink);
        setSyncingLoading(false);
        n.setNotification({
          title: `Something went wrong!`,
          sub: JSON.stringify(e.response.data),
          Icon: () => <FaceFrownIcon className="text-orange-400 h-6 w-6" />,
        });
      });
    window.location.reload();
  };

  const [confetti, setConfetti] = useState(false);
  useEffect(() => {
    // trigger things when showing
    // if all players have the same vote, confetti
    if (show && Object.keys(players).length > 1) {
      const votes = Object.values(players).map((p) => p.vote);
      if (votes.every((v) => v === votes[0])) {
        setConfetti(true);
      }
      setTimeout(() => {
        setConfetti(false);
      }, 5000);
    }
  }, [show]);

  const [gameSettingsOpen, setGameSettingsOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [createNewOpen, setCreateNewOpen] = useState(false);
  const [jiraConfigOpen, setJiraConfigOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentTourStep, setCurrentTourStep] = useState(0);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  // const [tempSavePlayer, setTempSavePlayer] = useState();
  // const [tempSaveTask, setTempSaveTask] = useState();

  // for the tour we add a local task to the room just to show how it works
  useEffect(() => {
    if (isTourOpen && currentTourStep === 0) {
      setValue();
    }
    if (isTourOpen && currentTourStep === 1) {
      setValue();
      setPlayers((p) => {
        return { ...p, 123: { name: "John Doe" } };
      });
      setTasksInRoom((p) => [
        { key: "#ab122", summary: "Some really big issue!" },
        { key: "#vv844", summary: "Even a bigger issue!" },
        ...p,
      ]);
      setTimeout(() => {
        setHighlightedTask("#ab122");
      }, 100);
    }
    if (isTourOpen && currentTourStep === 2) {
      setHighlightedTask("#ab122");
      setTimeout(() => {
        changeVote("3", true);
      }, 500);
    }
    if (isTourOpen && currentTourStep === 3) {
      setTimeout(() => {
        setPlayers((p) => {
          const b = Object.keys(p).reduce((acc, key) => {
            acc[key] = { ...p[key], vote: "3" };
            return acc;
          }, {});
          return { ...p, ...b };
        });
      }, 500);
    }
    if (isTourOpen && currentTourStep === 4) {
      setTimeout(() => {
        reveal(true);
      }, 500);
    }
    if (isTourOpen && currentTourStep === 5) {
      setTimeout(() => {
        setTasksInRoom((t) => {
          const f = [...t];
          f[0].finalVote = "3";
          return f;
        });
        reset(true);
      }, 500);
    }
  }, [joinedThisRoom, isTourOpen, currentTourStep]);

  useEffect(() => {
    if (!joinedThisRoom) {
      setIsTourOpen(false);
    }
  }, [joinedThisRoom]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Tour
        steps={steps}
        maskClassName="opacity-10 bg-white"
        accentColor="#5f3eea"
        closeWithMask={false}
        className="bg-gray-800 text-white rounded-lg"
        isOpen={isTourOpen}
        getCurrentStep={(currentStep) => setCurrentTourStep(currentStep)}
        onRequestClose={() => {
          setForcedReconnect(true);
          localStorage.setItem("tour", "true");
          setTasksInRoom((p) => {
            // delete from array where key is #ab122
            return p.filter((t) => t.key !== "#ab122" && t.key !== "#vv844");
          });
          setPlayers((p) => {
            delete p[123];
            return p;
          });
          socket.emit("request-tasks-in-room", gameId);
          socket.emit("request-force-players", gameId);
          setIsTourOpen(false);
        }}
      />
      {confetti && (
        <Confetti
          run={confetti}
          recycle={false}
          width={windowWidth}
          height={windowHeight}
        />
      )}
      <Settings
        player={players?.[u.user.id]}
        open={openSetting}
        name={u.user.firstName}
        setOpen={setOpenSettings}
        onSave={saveSettings}
      />
      <Share open={shareOpen} onClose={() => setShareOpen(false)} />
      <GameSettings
        runJiraConfig={() => {
          setJiraConfigOpen(true);
          setGameSettingsOpen(false);
        }}
        currentSequenceId={seq?.id}
        open={gameSettingsOpen}
        onSave={(fields) => {
          socket.emit("update-room", gameId, fields);
        }}
        onClose={() => setGameSettingsOpen(false)}
      />
      <PremiumError open={premiumError} onClose={() => setPremiumError()} />
      <CreateOrJoinModal
        currentlyNotInAGame={!joinedThisRoom && !forcedReconnect}
        unjoinedGameId={!joinedThisRoom && gameId ? gameId : undefined}
        onJoin={joinRoom}
        onSpectate={onSpectate}
        onCreate={createRoom}
      />
      <LinkModal
        onClose={() => setLinkModalOpen(false)}
        open={linkModalOpen}
        onRedirect={gotoJira}
      />
      <Jiraconfig
        open={jiraConfigOpen}
        catchJiraLink={onJiraRedirect}
        onClose={() => setJiraConfigOpen(false)}
        sync={syncPointsToJira}
        syncingLoading={syncingLoading}
      />
      <CreateModal
        open={createNewOpen}
        setOpen={setCreateNewOpen}
        gameId={gameId}
      />
      <SaveModall
        sequence={seq.values}
        open={saveModalOpen}
        players={players}
        setOpen={onClose}
        onSave={() => {
          socket.emit("next-task", gameId);
        }}
        roomId={gameId}
      />
      {/* {roomCreator && ( */}
      <Search
        tasks={tasksInRoom}
        catchJiraLink={onJiraRedirect}
        open={searchOpen}
        setOpen={setSearchOpen}
        canAdd={roomExists && joinedThisRoom && roomCreator}
        roomId={gameId}
      />
      {/* )} */}
      {/* <SideBar open={drawerOpen} setOpen={setDrawerOpen} /> */}
      <div className="h-full bg-gray-900 mb-24 sm:mb-0 relative isolate">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
          />
        </svg>
        {/* <div
          className="absolute left-[calc(50%-4rem)]  -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-20rem)] xl:left-[calc(50%-24rem)]"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          />
        </div> */}
        <div className="">
          <main
            ref={ref}
            className="flex min-h-screen flex-col items-center justify-center  lg:pr-[20rem] relative"
          >
            <div
              className="flex h-24  content-between  p-3 pb-6 absolute top-0 left-0 lg:pr-[20rem]"
              style={{ width: `${dims.width}px` }}
            >
              <div className="flex mr-auto font-bold text-white flex-col">
                <p className="flex items-center w-full">
                  <div
                    className="cursor-pointer flex"
                    onClick={() => setOpenSettings(true)}
                  >
                    <span>Hi, {u.user.firstName}! </span>
                    <CogIcon className="h-4 w-4 ml-1 text-white" />{" "}
                  </div>
                  {spectatorMode && joinedThisRoom && (
                    <span className="inline-flex ml-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                      <svg
                        className="h-1.5 w-1.5 fill-purple-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Spectating
                    </span>
                  )}
                  {roomCreator && joinedThisRoom && (
                    <span className="inline-flex ml-2 items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                      <svg
                        className="h-1.5 w-1.5 fill-blue-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Host
                    </span>
                  )}
                  {roomCreator && joinedThisRoom && hostWatchMode && (
                    <span className="inline-flex ml-2 items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                      <svg
                        className="h-1.5 w-1.5 fill-blue-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Host only mode
                    </span>
                  )}
                  {!spectatorMode && !roomCreator && joinedThisRoom && (
                    <span className="inline-flex ml-2 items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                      <svg
                        className="h-1.5 w-1.5 fill-green-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      Player
                    </span>
                  )}
                </p>{" "}
                {highlightedTaskFull && (
                  <div className="flex-col text-xs w-full">
                    <p className="font-normal">{highlightedTaskFull.key}</p>
                    <div className="flex items-center">
                      <span class=" rounded-full h-3 w-3 bg-indigo-500 mr-1"></span>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: highlightedTaskFull.summary,
                        }}
                      ></p>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-end pr-3">
                <span className="h-6 inline-flex ml-2 items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                  <UserGroupIcon className="h-3 w-3 fill-green-500" />{" "}
                  {players && Object.entries(players).length}
                </span>
                <span className="h-6 inline-flex ml-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                  <EyeIcon className="h-3 w-3 fill-purple-500" />{" "}
                  {spectators.length}
                </span>
              </div>
            </div>
            <div id="board">
              {highlightedTask &&
                !tasksInRoom.find((t) => t.key === highlightedTask)
                  ?.finalVote &&
                !show &&
                !!tasksInRoom.length && (
                  <div>
                    <p className="font-bold text-md mb-10 text-white animate-pulse">
                      It's time to vote!
                    </p>
                  </div>
                )}
              {tasksInRoom.length ? (
                <div
                  style={{
                    maxWidth: dims.width,
                  }}
                  className="flex h-full items-center gap-y-1 gap-x-1 flex-wrap justify-center"
                >
                  {players &&
                    Object.keys(players)?.map((pname) => {
                      const player = players[pname];
                      return (
                        <Card
                          id={"c" + pname}
                          voteRate={player?.correctVotes / player?.totalVotes}
                          x={x}
                          name={player.name}
                          show={show}
                          myValue={players[pname]?.vote}
                        />
                      );
                    })}
                </div>
              ) : (
                <div id="add">
                  <button
                    type="button"
                    disabled={!roomCreator}
                    onClick={() => setSearchOpen(true)}
                    className="relative max-w-full mx-5 w-72 block  rounded-lg border-2 border-dashed border-gray-600 p-12 text-center hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-600"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-600">
                      {"Start by adding tasks to this room via Jira (⌘ o)"}
                    </span>
                  </button>
                  <div className="relative my-3">
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-gray-900 px-6 text-gray-500">Or</span>
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={!roomCreator}
                    onClick={() => setCreateNewOpen(true)}
                    className="relative max-w-full mx-5 w-72 block  rounded-lg border-2 border-dashed border-gray-600 p-12 text-center hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-600"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-600">
                      {"Start by creating your own tasks (⌘ n)"}
                    </span>
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full content-between bg-gray-900 p-3 pb-6 fixed bottom-0 left-0">
              <div className="flex w-full" id="sequence">
                {!!tasksInRoom.length &&
                  seq?.values.map((item, i) => {
                    return (
                      <motion.div
                        id={`sequence-${i}`}
                        animate={
                          myValue === `${item}` ? "selected" : "notSelected"
                        }
                        variants={selectionVariants}
                        onClick={
                          !show
                            ? () => {
                                if (`${item}` === `${myValue}`) {
                                  changeVote("");
                                  return;
                                }
                                changeVote(`${item}`);
                              }
                            : null
                        }
                        className={`mx-2  border-2 border-black cursor-pointer flex  items-center justify-center rounded-xl bg-gray-800 text-sm font-bold text-white shadow-lg`}
                        style={{ width: "40px", height: "50px" }}
                      >
                        {item}
                      </motion.div>
                    );
                  })}
              </div>
            </div>
          </main>

          {/* Activity feed */}
          <aside className="bg-gray-800 lg:fixed lg:bottom-0 lg:right-0 lg:top-0 lg:w-[20rem] lg:overflow-y-auto lg:border-l lg:border-white/5  pb-[10rem] sm:pb-0">
            <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
              {joinedThisRoom && !!tasksInRoom.length ? (
                <button
                  id="reveal"
                  onClick={show ? reset : reveal}
                  className="text-sm font-semibold leading-6 text-gray-400"
                >
                  {show ? "Reset" : "Reveal"} cards
                </button>
              ) : (
                <div></div>
              )}
              {roomExists && joinedThisRoom ? null : (
                <button
                  onClick={createOrJoin}
                  className="text-sm font-semibold leading-6 text-gray-400"
                >
                  {!roomExists && !joinedThisRoom
                    ? "Start new room"
                    : roomExists && !joinedThisRoom
                    ? "Join this room"
                    : ""}
                </button>
              )}
              {joinedThisRoom && !roomCreator && (
                <button
                  onClick={leaveRoom}
                  className="text-sm font-semibold leading-6 text-red-400"
                >
                  Leave room
                </button>
              )}
              {joinedThisRoom && roomCreator && (
                <button
                  onClick={deleteRoom}
                  className="text-sm font-semibold leading-6 text-red-400"
                >
                  Delete room
                </button>
              )}
            </header>
            <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6">
              <span className="text-base font-semibold leading-7 text-white">
                Tasks
              </span>
              {joinedThisRoom && roomCreator && (
                <div className="flex items-center gap-x-2">
                  <button
                    onClick={() => setSearchOpen(true)}
                    className="text-sm leading-6 text-gray-400 hover:text-indigo-500"
                  >
                    Find Jira tasks
                  </button>
                  <p className="text-gray-500 text-xs">or</p>
                  <button
                    onClick={() => setCreateNewOpen(true)}
                    className="text-sm  leading-6 text-gray-400  hover:text-indigo-500"
                  >
                    Create one
                  </button>
                </div>
              )}
            </header>
            {!tasksInRoom.length && joinedThisRoom && (
              <div className="flex items-center justify-center px-4 py-4 sm:px-6 lg:px-8">
                <p className="text-gray-500 text-xs">
                  There are currently no tasks in this room.
                  {roomCreator &&
                    "Start by adding them with Jira (⌘ o) or creating them (⌘ n)"}
                </p>
              </div>
            )}
            {!tasksInRoom.length && !joinedThisRoom && (
              <div className="flex items-center justify-center px-4 py-4 sm:px-6 lg:px-8">
                <p className="text-gray-500 text-xs">
                  You are currently not in a room. Create or join one to start.
                </p>
              </div>
            )}
            <ul role="list" className=" divide-gray-800 p-2 px-5 bg-gray-800">
              {tasksInRoom.map((project, i) => (
                <li
                  id={`task-${i}`}
                  onClick={() => {
                    if (highlightedTask === project.key) {
                      unhighlightTask(project.key);
                      return;
                    }
                    hightlightTask(project.key);
                  }}
                >
                  <Task
                    task={project}
                    gameId={gameId}
                    setSaveModalOpen={setSaveModalOpen}
                    highlightedTask={highlightedTask}
                  />
                </li>
              ))}
            </ul>
            <div className="w-full px-5">
              {joinedThisRoom && (
                <>
                  {!!roomCreator && tasksInRoom.find((t) => !t.custom) && (
                    <button
                      type="button"
                      disabled={
                        !tasksInRoom.find((t) => !t.custom) ||
                        !tasksInRoom.filter((t) => t.finalVote).length
                      }
                      className="rounded-md disabled:text-gray-500 disabled:hover:bg-gray-800 mb-2 w-full flex items-center gap-x-2 justify-center bg-gray-700/50 px-3.5 py-2.5 text-xs  text-white shadow-sm hover:bg-gray-500/20"
                      onClick={() => syncPointsToJira()}
                    >
                      Sync to Jira{" "}
                      {syncingLoading ? (
                        <svg
                          aria-hidden="true"
                          class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      ) : (
                        <> 🚀</>
                      )}
                    </button>
                  )}
                  <div className="w-full flex gap-x-2">
                    {/* <button
                      type="button"
                      className="rounded-md  mb-2 w-full flex items-center gap-x-2 justify-center bg-gray-500/10 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500/20"
                      onClick={() => hostWatchModeTrigger()}
                    >
                      {hostWatchMode && "Disable"} Host-only mode
                      {hostWatchMode ? (
                        <EyeSlashIcon className="text-purple-400 h-5 w-5 pb-1" />
                      ) : (
                        <EyeIcon className="text-white h-5 w-5" />
                      )}{" "}
                    </button> */}
                    {
                      <button
                        type="button"
                        className="rounded-md  mb-2 w-full  flex items-center gap-x-2 justify-center bg-indigo-700/50 px-3.5 py-2.5 text-xs  text-white shadow-sm hover:bg-gray-500/20"
                        onClick={() => setShareOpen(true)}
                      >
                        Share
                        <FontAwesomeIcon
                          icon={faArrowUpFromBracket}
                          className="h-4 w-4  text-white"
                        />
                      </button>
                    }
                    {roomCreator && (
                      <button
                        type="button"
                        className="rounded-md  mb-2 w-full  flex items-center gap-x-2 justify-center bg-gray-700/50 px-3.5 py-2.5 text-xs  text-white shadow-sm hover:bg-gray-500/20"
                        onClick={() => setGameSettingsOpen(true)}
                      >
                        Settings
                        <CogIcon className="text-white h-5 w-5" />
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}
export const Card = ({
  show,
  myValue,
  x,
  name,
  width,
  height,
  voteRate,
  id,
}) => {
  const [hover, setHover] = useState(false);
  const val = () => {
    if (show) {
      return myValue ? myValue : "?";
    }

    return "";
  };
  return (
    <div id={id} className="flex flex-col">
      <p className="px-4 font-bold text-white">
        {(name || "").length > 9 ? name.substr(0, 9) + "\u2026" : name}{" "}
        {hover && voteRate && (
          <span className="text-xs text-gray-400">
            ({Math.ceil(voteRate * 100)}%)
          </span>
        )}
      </p>
      <motion.div
        variants={variants}
        initial="waiting"
        animate={show ? "waiting" : myValue ? "selected" : "waiting"}
        style={{
          width: width || "120px",
          height: height || "200px",
          rotateY: x,
        }}
        className={`m-3 border-2 border-black flex items-center justify-center rounded-xl relative ${
          show ? "bg-gray-800" : myValue ? "bg-gray-700" : "bg-gray-800"
        } text-4xl font-bold text-white shadow-lg`}
      >
        {val()}
      </motion.div>
    </div>
  );
};

const SideBar = ({ open, setOpen }) => {
  return (
    <div
      id="drawer-navigation"
      class={`fixed top-0 left-0 z-40 w-64 h-screen p-4 overflow-y-auto transition-transform ${
        !open ? "-translate-x-full" : ""
      } bg-white dark:bg-gray-800`}
      tabindex="-1"
      aria-labelledby="drawer-navigation-label"
    >
      <h5
        id="drawer-navigation-label"
        class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
      >
        Menu
      </h5>
      <button
        type="button"
        data-drawer-hide="drawer-navigation"
        aria-controls="drawer-navigation"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Close menu</span>
      </button>
      <div class="py-4 overflow-y-auto">
        <ul class="space-y-2 font-medium">
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 21"
              >
                <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
              </svg>
              <span class="ms-3">Dashboard</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">Kanban</span>
              <span class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
                Pro
              </span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">Inbox</span>
              <span class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                3
              </span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">Users</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">Products</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">Sign In</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
              </svg>
              <span class="flex-1 ms-3 whitespace-nowrap">Sign Up</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const Task = ({ task, setSaveModalOpen, highlightedTask, gameId }) => {
  return (
    <div
      key={task.id}
      className={`flex cursor-pointer flex-col items-center justify-between gap-x-6 gap-y-2 py-2 px-4 my-2 rounded-lg ${
        task.key === highlightedTask ? "bg-gray-600/90" : "bg-gray-700"
      }`}
    >
      <div className="flex items-center justify-between w-full">
        <div className="min-w-0">
          <div className="mt-1 flex flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-300">
            <p
              className=""
              dangerouslySetInnerHTML={{
                __html: task.summary,
              }}
            />
          </div>
          <div className="flex items-start gap-x-3 flex-col">
            <div className="flex items-center">
              {highlightedTask === task.key && (
                <span class=" rounded-full h-3 w-3 bg-indigo-500 mr-1 mt-1"></span>
              )}
              <p
                className={classNames(
                  statuses[
                    task.finalVote !== "" && task.finalVote !== undefined
                      ? "Complete"
                      : "In progress"
                  ],
                  "rounded-md mt-1 whitespace-nowrap 5 px-1.5  text-xs font-medium ring-1 ring-inset"
                )}
              >
                {task.finalVote || "Yet to vote"}
              </p>
            </div>
            <p className="text-sm font-semibold mt-3 leading-6 text-gray-200">
              {task.custom ? "#" : ""}
              {task.key?.substring(0, 7)}
            </p>
            {/* <div className="w-full flex justify-start mt-2">
                          {!project.custom && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(project.url);
                              }}
                              className="hidden rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-gray-200 shadow-sm  hover:bg-gray-700 sm:block"
                            >
                              View task
                              <span className="sr-only">, {project.name}</span>
                            </button>
                          )}
                        </div> */}
          </div>
        </div>
        <div className="flex flex-none items-center gap-x-4">
          <Menu as="div" className="relative flex-none">
            <Menu.Button
              onClick={(e) => e.stopPropagation()}
              className="-m-2.5 block p-2.5 text-gray-300 hover:text-gray-400"
            >
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {!task.custom && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.open(task.url);
                        }}
                        className={classNames(
                          active ? "bg-gray-700" : "",
                          "block px-3 py-1 text-sm w-full leading-6 text-gray-300"
                        )}
                      >
                        View
                      </button>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        socket.emit("add-task-to-room", {
                          task: {
                            ...task,
                          },
                          roomId: gameId,
                        });
                      }}
                      className={classNames(
                        active ? "bg-gray-700" : "",
                        "block px-3 py-1 text-sm w-full leading-6 text-gray-300"
                      )}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="mr-auto">
          {setSaveModalOpen && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSaveModalOpen(task.key);
              }}
              className="text-xs  leading-6 text-indigo-300 hover:text-indigo-400"
            >
              Set final points
            </button>
          )}
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(task.url);
          }}
          className="text-xs  leading-6 text-indigo-300 hover:text-indigo-400"
        >
          View
        </button>
      </div>
    </div>
  );
};
