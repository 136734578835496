import { useSession } from "@clerk/clerk-react";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { defaultAuthCatcherRelogin } from "./App";

export default function Jiraconfig({
  open,
  onClose,
  catchJiraLink,
  sync,
  syncingLoading,
}) {
  const [extededConfig, setExtendedConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [findError, setFindError] = useState(false);
  const [issue, setIssue] = useState();
  const s = useSession();
  const [activeStep, setActiveStep] = useState(0);

  const [query, setQuery] = useState("");

  const [steps, setSteps] = useState([
    { id: 0, name: "Find task", href: "#", status: "current" },
    { id: 1, name: "Find point field", href: "#", status: "upcoming" },
    { id: 2, name: "Validate", href: "#", status: "upcoming" },
  ]);

  const handleSearch = useCallback(
    debounce((value, e) => {
      t(value, e);
    }, 500),
    []
  );

  const t = async (q, e) => {
    if (!q) {
      setLoading(false);
      return;
    }
    const r = await axios
      .get(window.BACKEND_URL + `/exact-search/config/${q}`, {
        headers: {
          s_token: await s.session.getToken(),
          s_id: s.session.id,
        },
      })
      .catch((e) => {
        setFindError(true);
        defaultAuthCatcherRelogin(e, catchJiraLink);
        setLoading(false);
      });
    if (!r?.data) {
      return;
    }
    setFindError(false);
    setIssue(r.data);
    setLoading(false);
  };

  useEffect(() => {
    setFindError(false);
    setLoading(true);
    handleSearch(query);
  }, [query]);

  function extractSelectedIssues(inputString) {
    const urls = inputString.split(/[\s,]+/); // Split by space or comma
    const issues = [];

    urls.forEach((url) => {
      // Check for 'selectedIssue' parameter in the query string
      const matchQuery = url.match(/[?&]selectedIssue=([^&#]+)/);
      if (matchQuery) {
        issues.push(matchQuery[1]);
      } else {
        // Check for a key after '/browse/'
        const matchBrowse = url.match(/\/browse\/([^\/?&#]+)/);
        if (matchBrowse) {
          issues.push(matchBrowse[1]);
        }
      }
    });

    return issues;
  }

  const smartFindQuery = async (query) => {
    // remove , from query
    const urlQuery = extractSelectedIssues(query);
    if (urlQuery.length) {
      setQuery(urlQuery.join(" "));
      return;
    }
    const q = query.replace(",", " ");
    setQuery(q);
  };

  const [chosenField, setChosenField] = useState();

  const chooseField = async (field, value) => {
    setChosenField(field);
    setActiveStep(2);
    setSteps((p) => {
      const q = p;
      q[1].status = "complete";
      q[2].status = "current";
      return q;
    });
  };

  const save = async () => {
    await axios
      .get(window.BACKEND_URL + `/set/story-point-field/${chosenField}`, {
        headers: {
          s_token: await s.session.getToken(),
          s_id: s.session.id,
        },
      })
      .catch((e) => {
        setFindError(true);
        defaultAuthCatcherRelogin(e, catchJiraLink);
        setLoading(false);
      });
    await sync();
    setActiveStep(3);
    setSteps((p) => {
      const q = p;
      q[1].status = "complete";
      q[2].status = "complete";
      return q;
    });
    // onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                    <FontAwesomeIcon
                      icon={faArrowUpFromBracket}
                      className="h-6 w-6  text-indigo-400"
                    />
                  </div>
                  <div className="mt-2 text-left sm:mt-2">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold text-center leading-6 text-white"
                    >
                      We need one more thing
                    </Dialog.Title>
                    {!extededConfig && (
                      <>
                        <div className="mt-5">
                          <p className="text-sm text-gray-200">
                            We need to know which field you use for story points
                            in order to sync point properly. Sometimes team
                            configure this with a 'custom field' in Jira,
                            instead of the default 'Story points' field.
                          </p>
                        </div>
                        <button
                          className="inline-flex w-full mb-1 mt-5 justify-center rounded-lg bg-indigo-600 border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            setExtendedConfig(true);
                          }}
                        >
                          Configure in 3 steps
                        </button>
                      </>
                    )}
                    {extededConfig && (
                      <>
                        <nav aria-label="Progress">
                          <ol
                            role="list"
                            className="divide-y mt-3 divide-gray-700 rounded-md border border-gray-700 md:flex md:divide-y-0"
                          >
                            {steps.map((step, stepIdx) => (
                              <li
                                key={step.name}
                                onClick={() => {
                                  if (step.id > activeStep) return;
                                  setActiveStep(stepIdx);
                                  setSteps((p) => {
                                    const q = p;
                                    q[stepIdx].status = "current";
                                    return q;
                                  });
                                }}
                                className="relative md:flex md:flex-1"
                              >
                                {step.status === "complete" ? (
                                  <a
                                    href={step.href}
                                    className="group flex w-full items-center"
                                  >
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                        <CheckIcon
                                          className="h-6 w-6 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="ml-4 text-sm font-medium text-gray-300">
                                        {step.name}
                                      </span>
                                    </span>
                                  </a>
                                ) : step.status === "current" ? (
                                  <a
                                    href={step.href}
                                    className="flex items-center px-6 py-4 text-sm font-medium"
                                    aria-current="step"
                                  >
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                      <span className="text-indigo-400">
                                        {step.id + 1}
                                      </span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-indigo-400">
                                      {step.name}
                                    </span>
                                  </a>
                                ) : (
                                  <a
                                    href={step.href}
                                    className="group flex items-center"
                                  >
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                        <span className="text-gray-300 group-hover:text-gray-400">
                                          {step.id + 1}
                                        </span>
                                      </span>
                                      <span className="ml-4 text-sm font-medium text-gray-300 group-hover:text-gray-400">
                                        {step.name}
                                      </span>
                                    </span>
                                  </a>
                                )}

                                {stepIdx !== steps.length - 1 ? (
                                  <>
                                    {/* Arrow separator for lg screens and up */}
                                    <div
                                      className="absolute right-0 top-0 hidden h-full w-5 md:block"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        className="h-full w-full text-gray-600"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                      >
                                        <path
                                          d="M0 -2L20 40L0 82"
                                          vectorEffect="non-scaling-stroke"
                                          stroke="currentcolor"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </>
                                ) : null}
                              </li>
                            ))}
                          </ol>
                        </nav>
                        {activeStep === 0 && (
                          <div className="px-2">
                            <p className="text-sm text-gray-200 mt-5 ">
                              Start by finding a task in Jira thay already has
                              story points assigned.
                            </p>
                            <p className="text-xs font-bold ml-2 mt-5 mb-2  text-gray-200">
                              Enter URL here
                            </p>
                            <div className="flex items-center">
                              <input
                                type="text"
                                name="name"
                                onChange={(e) => smartFindQuery(e.target.value)}
                                value={query}
                                id="name"
                                className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-200 shadow-sm bg-gray-700  sm:text-sm sm:leading-6"
                                placeholder="https://jira.com/browse/EN-3293"
                              />
                              <button
                                onClick={() => {
                                  setActiveStep(1);
                                  setSteps((p) => {
                                    const q = p;
                                    q[0].status = "complete";
                                    q[1].status = "current";
                                    return q;
                                  });
                                }}
                                type="button"
                                disabled={loading || !issue}
                                className="ml-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                {loading ? (
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                ) : (
                                  "next"
                                )}
                              </button>
                            </div>
                            {findError && (
                              <p className="text-xs ml-2 mt-1 mb-2  text-red-400">
                                Could not find issue
                              </p>
                            )}
                            {issue && (
                              <p className="text-xs ml-2 mt-1 mb-2  text-indigo-400">
                                Issue found
                              </p>
                            )}
                          </div>
                        )}
                        {activeStep === 1 && (
                          <div className="px-2">
                            <p className="text-sm font-bold text-gray-200 mt-5 ">
                              Here are all the fields for this task. Find the
                              one where the value is equal to the story points
                              found in Jira.
                            </p>
                            <p className="text-sm  text-gray-400 mt-1">
                              We've already filtered out the fields that are not
                              numbers or not filled in.
                            </p>
                            <table className="min-w-full divide-y divide-gray-700 mt-2">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                  >
                                    Field name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                  >
                                    Value
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-800">
                                {Object.keys(issue.fields || {})
                                  .filter(
                                    (k) => typeof issue.fields[k] == "number"
                                  )
                                  .map((key) => (
                                    <tr
                                      key={key}
                                      onClick={() =>
                                        chooseField(key, issue.fields[key])
                                      }
                                      className="hover:bg-gray-700 transition duration-300 ease-in-out cursor-pointer "
                                    >
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white">
                                        {key}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                        {issue.fields[key]}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {activeStep === 2 && (
                          <div className="px-2">
                            <p className="text-sm text-gray-200 mt-5 ">
                              The last step is to validate that we found the
                              correct field. If the value below is correct,
                              press save. If not, go back and choose another.
                            </p>
                            <div className="flex flex-col">
                              <p className="text-sm font-bold text-gray-200 mt-5 ">
                                Confirm that the following Jira task:{" "}
                                <span className="ml-2 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30">
                                  {issue.key}
                                </span>
                              </p>
                              <p className="text-sm font-bold text-gray-200 mt-5 ">
                                Has the following story points in Jira:
                                <span className="ml-2 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30">
                                  {issue.fields[chosenField]}
                                </span>
                              </p>
                              <p className="text-sm font-bold text-gray-200 mt-5 ">
                                This means that the field name for story points
                                is:
                                <span className="ml-2 inline-flex items-center rounded-md bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30">
                                  {chosenField}
                                </span>
                              </p>
                              <button
                                className="inline-flex w-full mb-1 mt-5 justify-center rounded-lg bg-indigo-600 border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => {
                                  save();
                                }}
                              >
                                Confirm and sync
                                {(loading || syncingLoading) && (
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        {activeStep === 3 && (
                          <div className="px-2">
                            <p className="text-sm text-gray-200 mt-5 ">
                              <span className="font-bold">All done! </span>We
                              did a test sync to check if the everything is
                              working. Confirm that the tasks' story points for
                              this game are synced to Jira.
                            </p>
                            <p className="text-sm text-gray-200 mt-5 font-bold">
                              You can always rerun this wizard via the cog icon
                              in the upper left corner.
                            </p>
                            <div className="flex flex-col">
                              <button
                                className="inline-flex w-full mb-1 mt-5 justify-center rounded-lg bg-indigo-600 border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => {
                                  onClose();
                                }}
                              >
                                Close setup
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
