import { useUser } from "@clerk/clerk-react";
import {
  CloudArrowUpIcon,
  CurrencyDollarIcon,
  EyeIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Contact from "./Contact";

import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 1080"
    width={props.height * 1.7777777778}
    height={props.height}
    {...props}
  >
    <path
      fill="#f8fbfd"
      d="M1355.546 557c6.797 94.229-32.029 128.76-104.976 199.547-72.946 70.786-230.347 219.22-332.704 225.17-102.357 5.95-223.387-87.212-281.437-189.47-58.05-102.257-104.604-319.803-66.865-424.073 37.74-104.27 186.597-172.048 293.302-201.548 106.704-29.5 264.812-40.513 346.925 24.55C1291.905 256.238 1348.75 462.77 1355.546 557c6.797 94.229-32.029 128.76-104.976 199.547"
    />
    <foreignObject
      width={props.height}
      height={props.height}
      transform={`translate(${props.height * 1.37} ${props.height / 1.5})`}
    >
      <img src="/hero.png" alt="" />
    </foreignObject>
  </svg>
);

export default function Landing() {
  const u = useUser();
  const nav = useNavigate();
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
          />
        </svg>
        {/* <div
          className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          />
        </div> */}
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-32">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <div className="mt-24 sm:mt-32 lg:mt-16">
              <div className="inline-flex space-x-6">
                <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">
                  New release
                </span>
                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                  <span>Just shipped v1</span>
                </span>
              </div>
            </div>
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Scrum poker made easy.
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Scrum poker is a great way to estimate tasks as a team. But
              sometimes the tools get in the way. That's where we come in. With
              Pokee, you can focus on the task at hand, not the tool.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              {u?.user ? (
                <button
                  onClick={() => nav("/game")}
                  className="rounded-md border-2 border-black bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Create a new game
                </button>
              ) : (
                <>
                  <button
                    onClick={() => nav("/sign-up?redirect=/game")}
                    className="rounded-md border-2 border-black bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                  >
                    Get started for free
                  </button>
                  <button
                    onClick={() => nav("/sign-in?redirect=/game")}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Login <span aria-hidden="true">→</span>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            {/* <div className="relative">
              <div className="absolute top-0 left-0 w-full h-full">
                <SvgComponent height={600} />
              </div>
            </div> */}
            {/* <svg
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[20.875rem] max-w-full drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#273447"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#1f2937"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src="/sc.png" alt="" />
              </foreignObject>
            </svg> */}
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-8 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-8">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <motion.div
                animate={{ translateY: [0, 30, 0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatDelay: 0,
                }}
              >
                <img
                  src="/mock.png"
                  alt="App screenshot"
                  width={600}
                  height={600}
                  className="w-[67rem] rounded-md -mt-8"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <Feats />
      <Contact />
      <Footerb />
    </>
  );
}

const navigation = {
  main: [
    { name: "Contact", href: "/contact" },
    { name: "Terms", href: "/terms" },
    { name: "Privacy", href: "/privacy" },
    { name: "Features", href: "/" },
  ],
  social: [],
};

export function Footerb() {
  return (
    <footer className="bg-gray-900">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-gray-300 hover:text-gray-200"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {navigation.social.map((item) => (
            <Link
              key={item.name}
              href={item.href}
              className="text-gray-300 hover:text-gray-400"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </Link>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-200">
          &copy; 2023 Pokee.io, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

const features = [
  {
    name: "Seamless Jira integration",
    description:
      "Batch import tickets from Jira with a single click. No more copy/pasting. Sync your estimates back to Jira with a single click.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Specator mode",
    description:
      "Have a product owner or stakeholder that wants to watch? No problem. They can join as a spectator and watch the game unfold.",
    icon: EyeIcon,
  },
  {
    name: "Easy to use",
    description:
      "Join a game with a single click. Vote, reveal, and reset with a single click. No more fumbling around with clunky UIs.",
    icon: FaceSmileIcon,
  },
  {
    name: "Free for now",
    description:
      "As we build out more features, we'll start charging. But for now, it's free.",
    icon: CurrencyDollarIcon,
  },
];

export function Feats() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-500">
            Scrum poker done right
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
            Everything you need to poker your tasks
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Every used an online scrum poker tool and thought "this is great,
            but I wish it had..."? We did too. So we built it. And we're
            constantly adding more. Here are some of our favorites.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-200">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600  border-2 border-black">
                    <feature.icon
                      className="h-6 w-6 text-whitek"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-400">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
