import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { Fragment, useState } from "react";
import { selectionVariants, socket } from "./game";

export default function SaveModall({
  open,
  setOpen,
  sequence,
  roomId,
  taskId,
  players,
}) {
  const [toSave, setToSave] = useState();

  const onClose = () => {
    setToSave();
    setOpen();
  };

  const onSave = () => {
    socket.emit("set-final-vote", {
      roomId,
      taskId: open,
      vote: toSave,
      players,
    });
    onClose();
  };

  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      Ready to save?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-200">
                        Choose your final value. You can change it later in
                        Jira.
                      </p>
                      <div className="flex w-full flex-wrap">
                        {sequence.map((item) => {
                          return (
                            <motion.div
                              animate={
                                toSave === `${item}`
                                  ? "selected"
                                  : "notSelected"
                              }
                              variants={selectionVariants}
                              onClick={() => {
                                if (toSave === `${item}`) {
                                  setToSave();
                                  return;
                                }
                                setToSave(`${item}`);
                              }}
                              className="mx-2 px-3 py-6 cursor-pointer flex  items-center justify-center rounded-xl bg-gray-800 text-sm font-bold text-white shadow-lg"
                              style={{ width: "40px", height: "100%" }}
                            >
                              {item}
                            </motion.div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600  border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={onSave}
                  >
                    Save and next 🔥
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
