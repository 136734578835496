import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./App.css";

import {
  AuthenticateWithRedirectCallback,
  ClerkProvider,
  RedirectToSignIn,
  RedirectToSignUp,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import { dark } from "@clerk/themes";

import { AutoClose } from "./AutoClose";
import Landing from "./Landing";
import { Notifications } from "./Notification";
import PrivacyPage from "./PrivacyPage";
import TermsAndConds from "./TermsPage";
import Game from "./game";

if (!window.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export const defaultAuthCatcherRelogin = async (e, setLink) => {
  if (e.response?.data?.type === "redirect") {
    setLink?.(e.response.data?.link);
  }
};

function SignInInternal() {
  const [params] = useSearchParams();
  return (
    <div className="w-full h-full flex justify-center items-center">
      <RedirectToSignIn
        routing="path"
        signUpUrl={"/sign-up"}
        path="/sign-in"
        redirectUrl={
          window.FRONTEND_URL + params.get("redirect") || window.FRONTEND_URL
        }
      />
    </div>
  );
}

function SignUpInternal() {
  const [params] = useSearchParams();
  return (
    <div className="w-full h-full flex justify-center items-center">
      <RedirectToSignUp
        routing="path"
        path="/sign-up"
        redirectUrl={
          window.FRONTEND_URL + params.get("redirect") || window.FRONTEND_URL
        }
      />
    </div>
  );
}

function GameGuard() {
  const { gameId } = useParams();

  return (
    <>
      <SignedIn>
        <Game />
      </SignedIn>
      <SignedOut>
        <Navigate
          replace
          to={
            gameId
              ? `/sign-in?redirect=/game/${gameId}`
              : "/sign-in?redirect=/game"
          }
        />
      </SignedOut>
    </>
  );
}

function A() {
  return (
    <Router>
      <App />
    </Router>
  );
}

function App() {
  const navigate = useNavigate();
  return (
    <>
      <ClerkProvider
        navigate={(to) => navigate(to)}
        appearance={{
          baseTheme: dark,
          elements: {
            formFieldInput: "bg-gray-800 border-gray-700",
            footerActionLink: "text-indigo-300",
            formField__emailAddress: "bg-gray-800",
            otpCodeFieldInput: "bg-gray-700 border-gray-600",
            formResendCodeLink: "text-indigo-300",
            identityPreviewEditButton: "text-indigo-300",
            card: "bg-gray-800",
            formButtonPrimary: "bg-indigo-600",
          },
        }}
        publishableKey={window.REACT_APP_CLERK_PUBLISHABLE_KEY}
      >
        <Notifications />
        <Routes>
          <Route
            path="/sso-callback"
            element={<AuthenticateWithRedirectCallback />}
          />
          <Route path="/" element={<Landing />} />
          <Route path="/terms" element={<TermsAndConds />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/auto-close" element={<AutoClose />} />
          <Route path="/sign-in/*" element={<SignInInternal />} />
          <Route path="/sign-up/*" element={<SignUpInternal />} />
          <Route path="/game" element={<GameGuard />} />
          <Route path="/game/:gameId" element={<GameGuard />} />
          <Route
            path="/undefined/*"
            element={<Navigate replace to="/game" />}
          />
          <Route path="*" element={<Landing />} />
        </Routes>
      </ClerkProvider>
    </>
  );
}

export default A;
