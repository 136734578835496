import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  CogIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";

export const sequences = [
  {
    id: 0,
    name: "Fibo",
    username: "(1, 2, 3, 5, 8, 13, 21)",
    values: ["1", "2", "3", "5", "8", "13", "21"],
  },
  {
    id: 1,
    name: "Increm",
    username: "(1, 2, 3, 4, 5, 6)",
    values: ["1", "2", "3", "4", "5", "6"],
  },
  {
    id: 2,
    name: "Fun",
    username: "(🔥, 🚀, 🙋‍♀️, 🥷, 📱, 🤖)",
    values: ["🔥", "🚀", "🙋‍♀️", "🥷", "📱", "🤖"],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GameSettings({
  open,
  onClose,
  onSave,
  runJiraConfig,
  currentSequenceId,
}) {
  const [newSequence, setNewSequence] = useState(
    sequences.find((s) => s?.id === currentSequenceId) || sequences[0]
  );
  const save = () => {
    onSave({ sequenceId: newSequence?.id });
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                    <CogIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      Game settings
                    </Dialog.Title>
                    <div className="mt-5">
                      <Listbox value={newSequence} onChange={setNewSequence}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium leading-6 text-white">
                              Sequence
                            </Listbox.Label>
                            <div className="relative mt-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-gray-700 py-1.5 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2  sm:text-sm sm:leading-6">
                                <span className="inline-flex w-full truncate">
                                  <span className="truncate">
                                    {newSequence.name}
                                  </span>
                                  <span className="ml-2 truncate text-gray-300 font-bold">
                                    {newSequence.username}
                                  </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-200"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {sequences.map((person) => (
                                    <Listbox.Option
                                      key={person.username}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "bg-indigo-600 text-white"
                                            : "text-gray-200",
                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={person}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="flex">
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "truncate"
                                              )}
                                            >
                                              {person.name}
                                            </span>
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-indigo-200"
                                                  : "text-gray-200",
                                                "ml-2 truncate"
                                              )}
                                            >
                                              {person.username}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-indigo-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                </div>
                <div className="mt-5 h-full">
                  <button
                    type="button"
                    className="inline-flex w-full mb-2 justify-center rounded-md bg-indigo-600  border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => runJiraConfig()}
                  >
                    Run Jira config wizard
                  </button>
                  <button
                    type="button"
                    disabled={newSequence?.id === currentSequenceId}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600  border-2 border-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-nonefocus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => save()}
                  >
                    Save changes 🚀
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
